import React from 'react'
import Box from '../../../components/Box'
import Flex from '../../../components/Flex'
import Text from '../../../components/Text'
import BackgroundImage from '../../../components/BackgroundImage'
import { Media, responsive } from '../../../contexts/responsive'

import player from './player.svg'
import player_desk from './player_desk.svg'
import partner from './colorful_partner.png'
import partner_desk from './partner_desk.png'

const Player = () => {
  return (
    <Box pt={responsive('3.5em', '10vh')} pl={responsive('1.125rem', '11.25rem')} pr={responsive('1.125rem', '4.375rem')}>
      <Flex>
        <Box flex={1}>
          <Box ml={responsive('8%', 0)} mr={responsive('15.9%', '14.3%')}>
            <Media greaterThanOrEqual='lg'>
              <Box mb="0.5em">
                <BackgroundImage.Lazy src={player_desk} ratio={537 / 114} />
              </Box>
            </Media>
            <Text.Bold fontSize={responsive('1.75em', '2.375em')}>商業及合作夥伴</Text.Bold>
            <Text color="custom.textGray" fontSize={responsive('0.875em', '1.25em')}>這些都是我們合作過的夥伴，<br />列舉幾個給你看看：</Text>
          </Box>
          <Media lessThan='lg'>
            <Box mt="1.25em">
              <BackgroundImage.Lazy src={partner} ratio={596 / 776} />
            </Box>
          </Media>
        </Box>
        <Media lessThan='lg'>
          <Box width="3.125em">
            <BackgroundImage.Lazy src={player} ratio={113.56 / 528.97} />
          </Box>
        </Media>
        <Box width="65%" as={Media} greaterThanOrEqual='lg'>
          <BackgroundImage.Lazy src={partner_desk} ratio={1088 / 391} />
        </Box>
      </Flex>
    </Box>
  )
}

export default Player
