import React, { useEffect, useMemo, useRef, useState } from 'react'
// import ReactFullpage from '@fullpage/react-fullpage'
import { useTransform, useViewportScroll } from 'framer-motion'
import { navigate } from 'gatsby'
import { useWindowSize } from 'react-use'
// import { forwardRef } from '@chakra-ui/react';
import { useBreakpointValue } from '@chakra-ui/react'

import Box from '../../components/Box'
import SeeMore from '../../components/SeeMore'
import useHeader from '../../contexts/header/useHeader'
// import FullpageContext from './fullpageContext';
import Base from './Base'
import Player from './Player'
// import Start from './Start';

import data from './Base/data.svg'
import culture from './Base/culture.svg'
import quiz from './Base/quiz.svg'
import info from './Base/info.svg'
import cooperation from './Base/cooperation.svg'
import experience from './Base/experience.svg'
import data_desk from './Base/data-desk.svg'
import culture_desk from './Base/culture-desk.svg'
import quiz_desk from './Base/quiz-desk.svg'
import info_desk from './Base/info-desk.svg'
import cooperation_desk from './Base/cooperation-desk.svg'
import experience_desk from './Base/experience-desk.svg'
import data_quote from './Base/data-quote.svg'
import culture_quote from './Base/culture-quote.svg'
import quiz_quote from './Base/quiz-quote.svg'
import quiz_quote2 from './Base/quiz-quote-2.svg'
import info_quote from './Base/info-quote.svg'
import cooperation_quote from './Base/cooperation-quote.svg'
import experience_quote from './Base/experience-quote.svg'
import data_desk_quote from './Base/data-desk-quote.svg'
import culture_desk_quote from './Base/culture-desk-quote.svg'
import quiz_desk_quote from './Base/quiz-desk-quote.svg'
import quiz_desk_quote2 from './Base/quiz-desk-quote-2.svg'
import info_desk_quote from './Base/info-desk-quote.svg'
import cooperation_desk_quote from './Base/cooperation-desk-quote.svg'
import experience_desk_quote from './Base/experience-desk-quote.svg'
import { responsive } from '../../contexts/responsive'
import useLottieFiles from './useLottieFiles'

const bases = [
  {
    src: data,
    quote: data_quote,
    deskSrc: data_desk,
    deskQuote: data_desk_quote,
    anchor: 'data',
    href: 'https://whatthedata.cc/',
    trackerName: 'guide_whatthedata',
    // deskLeft: '63.5%',
    deskBottom: '36%',
  },
  {
    src: culture,
    quote: culture_quote,
    deskSrc: culture_desk,
    deskQuote: culture_desk_quote,
    anchor: 'culture',
    href: 'https://helloislander.cc/',
    trackerName: 'guide_helloislander',
  },
  {
    src: quiz,
    quote: quiz_quote,
    deskSrc: quiz_desk,
    deskQuote: quiz_desk_quote,
    anchor: 'quiz',
    bottom: '11%',
    deskBottom: '38%',
    deskLeft: '63%',
    href: 'https://superquiz.do/',
    trackerName: 'guide_superquiz',
    right: true,
    intro: 10,
    quote2: {
      intro: 30,
      bottom: '13.5%',
      quote: quiz_quote2,
      deskQuote: quiz_desk_quote2,
      href: 'https://ooopenlab.cc/',
      trackerName: 'guide_ooopenlab',
    },
  },
  {
    src: info,
    quote: info_quote,
    deskSrc: info_desk,
    deskQuote: info_desk_quote,
    anchor: 'info',
    href: 'https://infoinfo.relab.cc/',
    trackerName: 'guide_infoinfo',
    bottom: '11%',
  },
  {
    src: cooperation,
    quote: cooperation_quote,
    deskSrc: cooperation_desk,
    deskQuote: cooperation_desk_quote,
    anchor: 'cooperation',
    left: '31%',
    deskLeft: '51%',
    deskBottom: '31.1%',
    to: '/service',
    trackerName: 'guide_collab',
  },
  // {
  //   src: cooperation,
  //   quote: cooperation_quote,
  //   deskSrc: cooperation_desk,
  //   deskQuote: cooperation_desk_quote,
  //   anchor: 'cooked',
  //   deskBottom: '31.1%',
  //   left: '31%',
  //   deskLeft: '51%',
  //   to: '',
  // },
  {
    src: experience,
    quote: experience_quote,
    deskSrc: experience_desk,
    deskQuote: experience_desk_quote,
    anchor: 'experience',
    left: '22%',
    deskLeft: '11.75%',
    deskBottom: '39%',
    bottom: '7%',
    trackerName: 'guide_whatthedata',
  },
]

const threshold = 0.01

const Page = ({
  children,
  index,
  loaded,
  scrollY,
  windowHeight,
  windowWdith,
}) => {
  const ballRatio = useBreakpointValue({
    // base: 87 / 792,
    base: 0,
    lg: 89.4 / 984,
  })
  const [yPos, setYPos] = useState([0, 0])
  const ref = useRef()
  // const [measureRef, { height }] = useMeasure()
  const [currentPage, setCurrentPage] = useState(0)
  const active = useMemo(
    () => (typeof loaded !== 'boolean' || loaded) && index === currentPage,
    [currentPage, index, loaded]
  )
  const pageProgress = useTransform(scrollY, yPos, [-1, 1])
  useEffect(() => {
    function handleOnChange() {
      const p = pageProgress.get()
      if (p) {
        setCurrentPage(index)
      }
    }

    const unsubscribe = pageProgress.onChange(handleOnChange)

    return () => {
      unsubscribe()
    }
  }, [index, pageProgress])
  useEffect(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect()
      setYPos([
        rect.top -
          windowHeight / 2 -
          (windowHeight * ballRatio) / 2 +
          window.scrollY,
        rect.bottom - windowHeight / 2 + window.scrollY,
      ])
    }
  }, [windowHeight])
  // const mergedRef = useMergeRefs(ref, measureRef)
  return useMemo(
    () => (
      <Box
        w="full"
        position="relative"
        zIndex={index + 1}
        ref={ref}
        mb={responsive(0, index === 0 && '-1px')}
      >
        {React.cloneElement(children, {
          active,
          index,
          pageProgress,
          windowHeight,
          windowWdith,
        })}
      </Box>
    ),
    [active, children, windowHeight]
  )
}

const FullPage = ({ children }) => {
  const [, reload] = useState()
  const { width, height } = useWindowSize()
  const { scrollY } = useViewportScroll()
  // const pagePositions = useRef()
  const flattern = useMemo(
    () => children.reduce((all, c) => all.concat(c), []),
    [children]
  )
  useEffect(() => {
    if (height === Infinity) reload(true)
  }, [height])

  useEffect(() => {
    let lastY
    let prevUp
    let timer

    const listener = (e) => {
      const currentY = e.touches?.[0].clientY
      if (currentY > lastY || e.deltaY < 0) {
        if (prevUp) {
          if (scrollY.get() < threshold) {
            timer = setTimeout(() => {
              if (prevUp && scrollY.get() < threshold) {
                navigate('/#guide')
              }
            }, 1000)
            // console.log('move!!')
          }
        }
        prevUp = true
      } else {
        if (timer) clearTimeout(timer)
        prevUp = false
      }
      lastY = currentY
    }

    document.addEventListener('mousewheel', listener)
    document.addEventListener('touchmove', listener)
    return () => {
      document.removeEventListener('mousewheel', listener)
      document.removeEventListener('touchmove', listener)
      if (timer) clearTimeout(timer)
    }
  }, [scrollY])

  return (
    <Box
      pt={responsive(
        `${Math.round(((height - (1235 / 827) * width) / 2 / height) * 100)}vh`,
        0
      )}
    >
      {flattern.map((page, i) => (
        <Page
          // ref={pageRefs[i]}
          key={i + page?.props?.anchor}
          anchor={page?.props?.anchor}
          index={i}
          scrollY={scrollY}
          windowWdith={width}
          windowHeight={height}
        >
          {page}
        </Page>
      ))}
    </Box>
  )
}
const BasePage = ({ loaded }) => {
  const { headerHeight } = useHeader()
  const lottieJson = useLottieFiles()

  return (
    <Box mt={`-${headerHeight}`}>
      {useMemo(
        () => (
          <FullPage>
            {/* <Start anchor="guide" height="100vh" /> */}
            {bases.map((b, i) => (
              <Base {...b} i={i} key={i} lottie={lottieJson[b.anchor]} />
            ))}
            <Player />
            {/* <Footer anchor="footer" /> */}
          </FullPage>
        ),
        [loaded, lottieJson]
      )}
      <SeeMore page="guide" />
    </Box>
  )
}

export default BasePage
